<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableList'
import { getAction,downloadAction } from '@/command/netTool'
import Big from 'big.js'

export default {
  name:'dataReportverificationStatement',
  data() {
    return {
      records: [],
      totalData: null,
      current: 1,
      total: 1,
      params: { current: 1, pageSize: 10 },
      paramsValue: { host: '/api' },
      userIdList:[]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getTotal(params) {
      return new Promise((resolve) => {
        // if (this.totalData) {
        //   resolve(this.totalData)
        // } else {
        this.params = {
          ...this.params,
          ...params,
        }
        getAction('/reportForm/getCompletedOrderTotal', this.params).then((e) => {
          this.totalData = e.data
          resolve(e.data)
        })
        // }
      })
    },
    getSum(list) {
      let productQuantityCompleted = 0
      let totalAmountCompleted = 0
      list.forEach((e) => {
        productQuantityCompleted = parseFloat(new Big(e.productQuantityCompleted || 0).plus(productQuantityCompleted))
        totalAmountCompleted = parseFloat(new Big(e.totalAmountCompleted || 0).plus(totalAmountCompleted))
      })
      return {
        nickname: '合计',
        productQuantityCompleted,
        totalAmountCompleted,
      }
    },
    getList(params = this.params) {
      this.getTotal(params).then((total) => {
        getAction('/reportForm/getCompletedOrderPage', params).then((e) => {
          this.records = e.data['records']
           this.params = {...params}
          this.params['current'] = e.data['current']
          this.params['pageSize'] = e.data['pageSize']
          this.current = e.data['current']
          this.total = e.data['total']
          if (this.records.length !== 0) {
            this.records.push(this.getSum(e.data['records']))
            // this.records.push({
            //   orderCount: total.orderCountHj,
            //   totalAmount: total.totalAmountHj,
            //   productQuantity: total.productQuantityHj,
            //   productQuantityCompleted: total.productQuantityCompletedHj,
            //   totalAmountCompleted: total.totalAmountCompletedHj,
            //   nickname: '合计',
            //   type: '1',
            // })
            this.records.push({ ...total, nickname: '总计' })
          }
        })
      })
    },
    getHeader() {
      return [
        {
          type: 'rangePicker',
          name: '日期',
          keys: ['dateGe', 'dateLe'],
          props:{
            allowClear:true
          }
        },
        {
          type: 'input',
          name: '核销人',
          key: 'nickname',
        }
      ]
    },
    getColumns() {
      return [
        {
          title: '核销人',
          dataIndex: 'nickname',
        },
        {
          title: '核销数量',
          dataIndex: 'productQuantityCompleted',
          sorter: (a, b, sortOrder) => {
            if (a.nickname == '合计' || a.nickname == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.productQuantityCompleted - b.productQuantityCompleted
            }
          },
        },
        {
          title: '核销总额',
          dataIndex: 'totalAmountCompleted',
          sorter: (a, b, sortOrder) => {
            if (a.nickname == '合计' || a.nickname == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalAmountCompleted - b.totalAmountCompleted
            }
          },
          customRender: (text) => {
            return <span>{parseFloat(text).toFixed(2)}</span>
          },
        },
        {
          dataIndex: 'a1',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                display: !!records.userId,
                name: '详情',
                onClick: () => {
                  this.$router.push(`/dataReport/verificationStatementDetail?userId=${records.userId}`)
                  // api.command.edit.call(this, {
                  //   url: '/farmYsTrain/startTrain?id=' + records.id,
                  // })
                },
              },
            ].filter((e) => (e.display == undefined ? true : e.display))
          },
        },
      ]
    },
    onTransferData(data) {
      const list = data
      let productQuantity = 0
      let productQuantityCompleted = 0
      list.forEach((e) => {
        productQuantity += e.productQuantity
        productQuantityCompleted += e.productQuantityCompleted
      })
      list.push({ productQuantity, productQuantityCompleted })
      return data
    },
    onChange(data, filter) {
      this.getList(data)
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            const { nickname, dateGe,dateLe} = this.params
            let urlParam =''
            if (nickname){
                urlParam = urlParam + `nickname=${nickname}`
            }
            if(dateGe){
               urlParam = (urlParam ? `${urlParam}&` : urlParam) + `dateGe=${dateGe}&dateLe=${dateLe}`
            }
             if(this.userIdList.length){
               urlParam = (urlParam ? `${urlParam}&` : urlParam) + `userIdList=${this.userIdList}`
            }
            downloadAction(
              `/api/reportForm/exportCompletedOrderReport?${urlParam}`,
              '核销报表.xlsx'
            )
          },
        },
      ]
    },
    selectRow(data, records) {
      this.userIdList = records.map((d) => d.userId)
    },
  },
  render() {
    return (
      <TemplateHeaderTableNew
        records={this.records}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        onChange={this.onChange}
        rightButton={this.getRightButton()}
        button={[]}
        filterRightButtonKey={['refresh', 'setting']}
         onSelectRow={this.selectRow}
      />
    )
  },
}
</script>
